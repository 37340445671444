<template>
    <div style="background: linear-gradient(to bottom right, #409EFF, #409EFF);height:100vh">

        <ul class="bg-bubbles">
            <li v-for="i in 10" :key="i"></li>
        </ul>

        <div class="content">

            <div class="title">
                <div class="main">
                    <img alt="logo" class="main-logo" src="@/assets/logo.png" />
                    <span class="main-title">{{ fullTitle }}</span>
                </div>
                <div class="desc">{{ subTitle }}</div>
            </div>

            <div v-if="client && client==='桌面端'" class="qrcode">
                <div style="display:flex;justify-content:center;align-items: center">
                    <div id="wx_reg"></div>
                    <!-- <el-link :href="qrcodeUrl"><img :src="imgSrc" :srcset="imgSrcSet" alt="企业微信登录" /></el-link> -->
                </div>
            </div>

        </div>

        <div class="footer">
            <div class="links">
                <el-link icon="el-icon-link" href="https://work.weixin.qq.com/">{{ '企业微信' }}</el-link>
                <el-link icon="el-icon-link" href="https://www.meatou.com/">{{ '米拓科技' }}</el-link>
            </div>
            <div class="copyright">
                Copyright @ {{ copyright }}
            </div>
        </div>
        <!-- <div>{{store.state.app.fullTitle}}</div> -->

    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from "vue-router"
import { APPModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user'
import { ElMessageBox } from 'element-plus';
import { ElMessage } from 'element-plus';
import { ElLoading } from 'element-plus';
import request from '@/plugin/request';
export default defineComponent({
    setup () {

        const route = useRoute();
        const router = useRouter();
        const client = ref('');


        document.onkeydown = (e) => {

            if (e.ctrlKey && e.altKey && e.key === 'd') {
                ElMessageBox.prompt('请输入开发秘钥', '开发模式', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    showCancelButton: false,
                    inputType: 'password'
                })
                    .then(({ value }) => {

                        if (value === '88614826') {
                            UserModule.login({ username: 'adun', password: '123456' }).then((res) => {
                                console.log(res)
                                router.push({ path: '/', query: {} })
                            })
                        }

                    })
                    .catch(() => {
                        ElMessage({ type: 'info', message: '取消输入', });
                    });
            }

        }


        nextTick(() => {
            if (!route.query.code) {
                window.WwLogin({
                    "id": "wx_reg",
                    "appid": "ww28471c5a45fa1161",
                    "agentid": "1000005",
                    "redirect_uri": `${process.env.VUE_APP_BASE_WORK_URL}/Login`,
                    "state": "",
                    "href": `${process.env.VUE_APP_BASE_WORK_URL}/api/login.css`,
                });
            }
        })

        onMounted(() => {
            console.log('解析参数')

            console.log(route.query)

            if (route.query.code) {

                let loading = ElLoading.service({ fullscreen: true });
                UserModule.login({ code: route.query.code }).then((res) => {
                    router.push({ path: '/', query: {} }).then(() => { loading.close() })
                })

            } else {

                if (/wxwork/.test(navigator.userAgent)) {
                    client.value = '企业微信'
                } else {
                    client.value = '桌面端'
                }

            }



        })

        return {
            fullTitle: APPModule.fullTitle,
            subTitle: APPModule.fullTitle,
            copyright: APPModule.copyright,
            client,
        };
    },
    components: {

    },
});
</script>

<style>
.impowerBox .qrcode {width: 200px;}
.impowerBox .title {display: none;}
.impowerBox .info {width: 200px;}
.status_icon {display: none  !important}
.impowerBox .status {text-align: center;}
</style>

<style lang="scss" scoped>
.content {
    font-family: 'Microsoft YaHei';
    padding: 32px 0;
    flex: 1;
    @media (min-width: 768px) {
        padding: 56px 0 24px;
    }

    .title {
        // height: 100px;
        color: #e4e7ed;
        .main {
            display: flex;
            justify-content: center;
            align-items: center;

            .main-logo {
                line-height: 44px;
                height: 44px;
                width: 44px;
                margin-right: 10px;
            }

            .main-title {
                line-height: 44px;
                height: 44px;
                font-size: 33px;
            }
        }

        .desc {
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .qrcode {
        margin-top: 20px;
    }
}

.footer {
    color: #e4e7ed;
    position: absolute;
    bottom: 20px;
    padding: 0px;
    width: 100%;
    margin: 0 auto;
    // padding: 48px 16px 24px;
    /*margin: 48px 0 24px;*/
    text-align: center;
    .copyright {
        // color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
    }
    .links {
        margin-bottom: 8px;
        a:not(:last-child) {
            margin-right: 40px;
        }
        a {
            color: #e4e7ed;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
        }
    }
}
</style>

<style lang="scss" scoped>
.bg-bubbles {
    position: absolute;
    // 使气泡背景充满整个屏幕；
    top: 0;
    left: 0;
    width: 95%;
    height: 95%;
    // 如果元素内容超出给定的宽度和高度，overflow 属性可以确定是否显示滚动条等行为；
    overflow: hidden;
    li {
        position: absolute;
        // bottom 的设置是为了营造出气泡从页面底部冒出的效果；
        bottom: -160px;
        // 默认的气泡大小；
        width: 40px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.15);
        list-style: none;
        // 使用自定义动画使气泡渐现、上升和翻滚；
        animation: square 15s infinite;
        transition-timing-function: linear;
        // 分别设置每个气泡不同的位置、大小、透明度和速度，以显得有层次感；
        &:nth-child(1) {
            left: 10%;
        }
        &:nth-child(2) {
            left: 20%;
            width: 90px;
            height: 90px;
            animation-delay: 2s;
            animation-duration: 7s;
        }
        &:nth-child(3) {
            left: 25%;
            animation-delay: 4s;
        }
        &:nth-child(4) {
            left: 40%;
            width: 60px;
            height: 60px;
            animation-duration: 8s;
            background-color: rgba(255, 255, 255, 0.3);
        }
        &:nth-child(5) {
            left: 70%;
        }
        &:nth-child(6) {
            left: 80%;
            width: 120px;
            height: 120px;
            animation-delay: 3s;
            background-color: rgba(255, 255, 255, 0.2);
        }
        &:nth-child(7) {
            left: 32%;
            width: 160px;
            height: 160px;
            animation-delay: 2s;
        }
        &:nth-child(8) {
            left: 55%;
            width: 20px;
            height: 20px;
            animation-delay: 4s;
            animation-duration: 15s;
        }
        &:nth-child(9) {
            left: 25%;
            width: 10px;
            height: 10px;
            animation-delay: 2s;
            animation-duration: 12s;
            background-color: rgba(255, 255, 255, 0.3);
        }
        &:nth-child(10) {
            left: 85%;
            width: 160px;
            height: 160px;
            animation-delay: 5s;
        }
    }
    // 自定义 square 动画；
    @keyframes square {
        0% {
            opacity: 0.5;
            transform: translateY(0px) rotate(45deg);
        }
        25% {
            opacity: 0.75;
            transform: translateY(-400px) rotate(90deg);
        }
        50% {
            opacity: 1;
            transform: translateY(-600px) rotate(135deg);
        }
        100% {
            opacity: 0;
            transform: translateY(-1000px) rotate(180deg);
        }
    }
}
</style>